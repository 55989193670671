<template>
  <section id="portfolio">
    <div class="title-section">
      <h1>Portfolio</h1>
    </div>
    <div class="container">
      <div class="project" data-aos="fade-up">
        <div class="project__image">
          <img
            :src="require(`../assets/sites/parkavenue.gif`)"
            alt=""
          />
        </div>
        <div class="description">
          <h3>ParkAvenue</h3>
          <p>
            Park Avenue is a stylish and comfortable multifunctional family residential complex. The neighborhood is being built in the Arabkir community of Yerevan, at Griboyedov 48/6.
          </p>

        </div>
      </div>

      <div class="project" data-aos="fade-up">
        <div class="project__image">
          <img
              :src="require(`../assets/sites/gregorian.gif`)"
              alt=""
          />
        </div>
        <div class="description">
          <h3>Gregorian</h3>
          <p>
            Armenian wine, wine, red wine, white wine, wines, blackberry, pomegranate, cherry.
          </p>

        </div>
      </div>

      <div class="project" data-aos="fade-up">
        <div class="project__image">
          <img
              :src="require(`../assets/sites/reallynews.gif`)"
            alt=""
          />
        </div>
        <div class="description">
          <h3>Really News</h3>
          <p>
            ReallyNews daily news website.

          </p>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.btn {
  display: block;
  padding: 1rem 3rem;
  transition: 0.25s;
  margin-top: 1.4rem;
}
.btn:hover {
  background: var(--purple);
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1rem;
}
.project {
  max-width: 40rem;
  margin: 0 auto;
  border-radius: 0.8rem;
}
.project:hover .project__image img {
  transform: scale(1.05);
}
.project:hover {
  box-shadow: 0 0 10px 0px rgb(0 0 0 / 60%);
}
.project__image {
  overflow: hidden;
  height: 20rem;
}
.project__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.25s;
}
.description {
  text-align: center;
  padding: 2rem 2rem;
}
</style>
