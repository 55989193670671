<template>
  <footer>
    <p>&copy; {{ data }} All rights reserved</p>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      data: 0,
    };
  },
  mounted() {
    const date = new Date().getFullYear();
    this.data = date;
  },
};
</script>

<style scoped>
footer {
  display: flex;
  justify-content: center;
  color: var(--black);
  padding: 2rem 0;
  background: var(--white);
}
</style>
