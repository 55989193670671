<template>
  <section id="sobre" ref="target">
    <div class="title-section">
      <h1>About me</h1>
    </div>
    <div class="container">
      <div class="profile" data-aos="fade-right"></div>
      <div class="about" data-aos="fade-left">
        <h2>Hello, my name is Zhora Khzmalyan</h2>
        <p>

          I'm a young developer trained as a computer technician. I am passionate about programming and learning. I like to always improve myself as a professional and I always look for new challenges and experiences to improve myself.
        </p>
        <div class="social-links">
          <a href="https://www.linkedin.com/in/zhora-khzmalyan-4642591a0/" target="_blank">
            <span class="social__span">
              Linkedin
            </span>
            <img src="../assets/linkedin-icon.svg" alt="Icone Linkedin" />
          </a>

          <a href="https://github.com/DZORAJAN1996" target="_blank">
            <span class="social__span">
              GitHub
            </span>
            <img src="../assets/github-icon.svg" alt="Icone GitHub" />
          </a>

          <a href="https://www.instagram.com/zhora_khzmalyan/" target="_blank">
            <span class="social__span">
              Instagram
            </span>
            <img src="../assets/instagram-icon.svg" alt="Icone Instagram" />
          </a>
          <a href="https://www.facebook.com/jora.xzmalyan/" target="_blank">
            <span class="social__span">
              Facebook
            </span>
            <img src="../assets/facebook.svg" alt="Icone Facebook" />
          </a>


        </div>
        <a class="btn" href="/ZHORA-CV.pdf" download="ZHORA-CV.pdf">download resume</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
section {
  background: var(--white);
  color: var(--black);
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.profile {
  background-image: url("../assets/portfolio.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 45rem;
}
.about {
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
}
.about p {
  font-size: 2rem;
  letter-spacing: 0.1rem;
  line-height: 3rem;
}
.btn {
  padding: 1.8rem;
  border-radius: 0.8rem;
  margin-top: 3rem;
  align-self: flex-start;
  transition: 0.25s;
}
.btn:hover {
  background: var(--purple);
}
.social-links {
  display: flex;
  gap: 2rem;
}
.social-links a {
  width: 3.4rem;
  height: 4rem;
  position: relative;
}
.social-links .social__span {
  opacity: 0;
  visibility: hidden;
  top: 0;
  position: absolute;
  left: 50%;
  font-weight: 600;
  transform: translateX(-50%);
  color: var(--white);
  background: var(--purple);
  padding: 1rem;
  font-size: 1.3rem;
  border-radius: 0.4rem;
  transition: 0.25s;
}
.social-links .social__span::after {
  content: "";
  border: solid var(--purple);
  border-width: 0 3px 3px 0;
  display: inline-block;
  position: absolute;
  right: 50%;
  top: -0.5rem;
  padding: 3px;
  transform: rotate(-135deg) translate(-50%);
}
.social-links a:hover .social__span {
  top: 110%;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 700px) {
  .container {
    display: block;
  }
  .about {
    text-align: center;
    margin-top: 4rem;
  }
  .btn {
    align-self: center;
  }
  .social-links {
    justify-content: center;
  }
  .profile {
    background-size: cover;
  }
}
</style>
